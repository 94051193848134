.success-tagged {
  background-color: #d4edda;
}

.warning-tagged {
  background-color: #fff3cd;
}

.error-tagged {
  background-color: #f8d7da;
}

.splitStatus {
  background-color: lightgray;
  text-decoration: line-through;
}